<template>
  <div>
    <div class="flex items-center justify-center w-full">
      <label
        class="flex flex-col rounded-lg border border-blue-600 w-full cursor-pointer"
      >
        <div class="h-full w-full flex flex-row">
          <div class="flex w-1/4 place-content-start">
            <div
              class="w-full text-md bg-blue-600 text-white text-center font-medium pb-3 pt-4 rounded-l flex justify-center"
            >
              <svg class="svg-icon h-5 w-auto inline-block" viewBox="0 0 20 20">
                <path
                  fill="#FFFFFF"
                  d="M19.521,7.267c-0.144-0.204-0.38-0.328-0.631-0.328h-3.582l-0.272-1.826c-0.055-0.379-0.379-0.656-0.76-0.656
							H9.802l-0.39-0.891c-0.123-0.279-0.399-0.46-0.704-0.46H1.11c-0.222,0-0.434,0.096-0.58,0.264C0.385,3.537,0.319,3.76,0.349,3.981
							l1.673,12.243c0,0,0,0,0,0.002v0.004c0.015,0.113,0.06,0.213,0.119,0.303c0.006,0.009,0.006,0.023,0.012,0.033
							c0.012,0.016,0.033,0.024,0.046,0.04c0.054,0.065,0.114,0.118,0.185,0.161c0.027,0.018,0.051,0.035,0.078,0.048
							c0.099,0.045,0.206,0.078,0.32,0.078h0.002l0,0h13.03c0.323,0,0.611-0.201,0.722-0.505l3.076-8.416
							C19.698,7.735,19.663,7.474,19.521,7.267z M8.203,4.644l0.391,0.889c0.123,0.279,0.399,0.461,0.704,0.461h4.315l0.141,0.944H5.859
							c-0.323,0-0.611,0.201-0.723,0.505l-2.011,5.505L1.992,4.644H8.203z M15.276,15.356H3.882l2.515-6.879H17.79L15.276,15.356z"
                ></path>
              </svg>
              <span class="inline-block ml-1">
                Choose file
              </span>
            </div>
          </div>
          <div class="flex items-center text-gray-500 pl-3 w-3/4">
            {{ inputChooseFileText }}
          </div>
        </div>
        <input
          :accept="inputAcceptFiles"
          ref="fileInputRef"
          type="file"
          class="hidden"
          :name="inputName"
          :id="inputId"
          @change="inputFileSelected($event)"
          v-if="noOfFiles == ''"
        />
        <input
          :accept="inputAcceptFiles"
          ref="fileInputRef"
          type="file"
          class="hidden"
          :name="inputName"
          :id="inputId"
          @change="inputFileSelected($event)"
          v-if="noOfFiles == 'multiple'"
          multiple
        />
      </label>
    </div>
    <p class="text-sm text-gray-400 mt-2">
      <span
        ><strong>*</strong> Only {{ friendlyFileTypes }} files are
        accepted</span
      >
    </p>
  </div>
</template>
<script>
export default {
  name: "FileInput",
  props: {
    noOfFiles: {
      type: String,
      default: () => "",
    },
    chooseFileText: {
      type: String,
      default: () => "No file chosen",
    },
    acceptedFileTypes: {
      type: Array,
      required: true,
    },
    inputId: {
      type: String,
      default: () => "file-input",
    },
    inputName: {
      type: String,
      default: () => "file-input",
    },
  },
  data() {
    return {
      inputChooseFileText: null,
    };
  },
  computed: {
    inputAcceptFiles() {
      var acceptedFiles = this.acceptedFileTypes.map((i) => "." + i);
      return acceptedFiles.join(",");
    },
    friendlyFileTypes() {
      return this.acceptedFileTypes
        .join(", ")
        .replace(/, ((?:.(?!, ))+)$/, " and $1");
    },
  },
  mounted() {
    this.inputChooseFileText = this.chooseFileText;
  },
  methods: {
    resetComponent() {
      this.$refs.fileInputRef.value = null;
      this.inputChooseFileText = this.chooseFileText;
    },
    inputFileSelected(e) {
      if (e.target.files.length > 1) {
        this.inputChooseFileText = e.target.files.length + " files";
      } else {
        this.inputChooseFileText = e.target.files[0].name;
      }
    },
  },
};
</script>
<style scoped></style>
