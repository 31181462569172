<template>
  <div class="flex gap-x-4 items-center min-h-12 max-h-12">
    <router-link
      :to="{
        name: 'Dashboard',
      }"
    >
      <img
        :src="require('@/assets/images/' + logoImage + '.svg')"
        v-if="open"
        class="h-10 cursor-pointer duration-500"
      />
      <img
        :src="require('@/assets/images/' + logoImage + '-small.svg')"
        v-else
        class="h-10 cursor-pointer duration-500"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SidebarLogo",
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
    white: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    logoImage() {
      if (this.white) {
        return "logo-white";
      } else {
        return "logo";
      }
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
