<template>
  <div class="flex items-center shadow relative bg-white">
    <Logo :open="true" :white="false" class="ml-2" />
    <div class="w-full flex justify-end items-center">
      <Account />
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Common/Sidebar/Logo";
import Account from "@/components/Common/Navbar/Account";

export default {
  name: "NavbarPlain",
  components: { Account, Logo },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style scoped></style>
