<template>
  <div class="mx-auto relative ml-2.5">
    <Navbar class="px-10" />
    <div id="add-users-form" class="mx-10 py-4">
      <div class="text-black text-3xl font-bold ml-1">Add Users</div>
      <div class="form-container">
        <div class="users pt-1">
          <div
            class="ob-kit-users bg-nColorFillGray border border-nColorStrokeGray rounded-xl text-black flex"
          >
            <div class="w-1/2 p-8 border-r border-gray-200">
              <div class="kit-employees-list mt-4">
                <label class="font-semibold required-field" for="employeeList"
                  >Users</label
                >
                <FileInput
                  :acceptedFileTypes="['xls']"
                  inputId="employeeList"
                  inputName="employeeList"
                  class="mt-1 w-4/5"
                  @change="setEmployeeList($event)"
                />
                <div class="file-error-message mt-4" v-if="fileError">
                  <p class="text-red-500 text-sm">
                    Please upload an employee list file
                  </p>
                </div>
                <div class="text-sm text-red-500 mt-3">
                  <p v-if="v$.employeeList.$invalid && checkValidation">
                    {{ v$.employeeList.$silentErrors[0].$message }}
                  </p>
                </div>
              </div>
              <div class="choose-kit mt-6">
                <p class=" font-semibold required-field">
                  Select a Kit
                </p>
                <div class="flex flex-col">
                  <div class="w-4/5" v-if="obKits">
                    <Multiselect
                      placeholder="Name of the kit"
                      ref="multiselect"
                      :canClear="false"
                      :caret="true"
                      class="multiselect-box"
                      :options="
                        obKits.map((kit) => ({
                          value: kit.id,
                          label: kit.name,
                        }))
                      "
                      v-model="obkit"
                    />
                  </div>
                  <div class="mt-2 text-sm text-gray-400">
                    <strong>*</strong>
                    Click
                    <router-link
                      :to="{
                        name: 'CreateOnboardingKitCatalog',
                        params: { tag: defaultTagForCatalog },
                      }"
                      class="text-nColorBlue underline font-semibold"
                    >
                      here
                    </router-link>
                    to create a new Kit
                  </div>
                </div>
                <div class="text-sm text-red-500 mt-3">
                  <p v-if="v$.obkit.$invalid && checkValidation">
                    {{ v$.obkit.$silentErrors[0].$message }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-1/2 p-8">
              <div class="mb-6">
                <span class="text-2xl font-medium">User fields needed</span>
                <ul class="list-disc list-inside font-normal mt-2">
                  <li class="required-field">First name</li>
                  <li class="required-field">Last name</li>
                  <li class="required-field">Date of offer acceptance</li>
                  <li class="required-field">Date of joining</li>
                  <li>Personal email address (If candidate)</li>
                  <li>Official email address (If employee)</li>
                  <li>Birthday</li>
                </ul>
              </div>
              <a
                class="transition shadow duration-300 ease-out tracking-wider bg-white text-nColorBlue
                        px-6 py-3 rounded-lg uppercase font-bold border border-nColorBlue hover:bg-nColorBlue
                        hover:text-white"
                :href="obkSampleExcel"
                target="_blank"
                >Download sample.xls</a
              >
            </div>
          </div>
        </div>
      </div>
      <form action="" @submit.prevent="submitUsers()" novalidate>
        <div class="flex place-content-end mt-4">
          <div v-show="errorMsg" class="text-red-500 place-self-end p-3">
            <p class="text-md font-semibold">
              {{ errorMsg }}
              <a
                v-if="errorFile"
                class="download-sample text-sm underline text-red-500 inline-block mt-4"
                :href="errorFile"
                target="_blank"
                >(Click here)</a
              >
            </p>
          </div>
          <div
            v-if="successMsg"
            class="success text-green-500 place-self-end p-3"
          >
            <p class="text-md font-semibold">
              {{ successMsg }}
            </p>
          </div>
          <div>
            <ButtonInput
              buttonText="Continue"
              :loading="loading"
              :buttonClass="
                'transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
              "
              :loadingClass="
                'cursor-not-allowed transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
              "
            />
          </div>
        </div>
      </form>
    </div>
    <ConfirmationModal
      @closeModal="modal.show = false"
      @continue="continueSubmit()"
      :modal="modal"
    />
  </div>
</template>
<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Navbar from "@/components/Common/Navbar/Plain";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { fileSources } from "@/common/app.config";
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";
import ButtonInput from "@/components/Elements/ButtonInput.vue";
import FileInput from "@/components/Elements/FileInput.vue";

export default {
  name: "AddUsersToOnboardingKitForm",
  components: {
    Navbar,
    Multiselect,
    ConfirmationModal,
    ButtonInput,
    FileInput,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    const vue = this;
    ApiService.get(apiResource.onboardingKit.getAllKits)
      .then(({ data }) => {
        vue.obKits = data.data.kits;
      })
      .catch(() => {});
  },
  data() {
    return {
      defaultTagForCatalog: "all",
      employeeList: null,
      fileError: null,
      successMsg: null,
      errorFile: null,
      errorMsg: null,
      errorObj: null,
      obkit: "",
      obKits: null,
      ignoreBday: false,
      loading: false,
      checkValidation: false,
      obkSampleExcel: fileSources.obkSampleExcel,
      modal: {
        show: false,
        heading: "Birthday Warning",
        question: null,
        action: "continue",
      },
    };
  },
  mounted() {},
  validations() {
    return {
      employeeList: { required },
      obkit: { required },
    };
  },
  methods: {
    setEmployeeList(event) {
      this.employeeList = event.target.files[0];
      this.fileError = false;
    },
    continueSubmit() {
      this.ignoreBday = true;
      this.submitUsers();
    },
    submitUsers() {
      this.checkValidation = true;
      if (!this.v$.$invalid) {
        const formData = new FormData();
        this.loading = true;

        formData.append("onboarding_kit_id", this.obkit);

        formData.append("users", this.employeeList, this.employeeList.name);

        formData.append("ignore_bday", this.ignoreBday);

        ApiService.post(apiResource.addUsers.validateUsers, formData)
          .then(({ data }) => {
            this.errObj = null;
            this.errorMsg = null;
            this.$router.push({
              name: "AddUsersSummary",
              params: { id: data.data.obk_flow_id },
            });
          })
          .catch((error) => {
            console.log(error);
            if (error && typeof error.response.data.msg == "object") {
              this.errObj = error.response.data.msg;
            } else {
              if (error.response.data.warning) {
                // show Popup
                this.modal.show = true;
                this.modal.question = error.response.data.msg;
              } else {
                this.errorMsg = error.response.data.msg;
              }
            }

            this.errorFile = error.response.data.errors;
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="postcss"></style>
