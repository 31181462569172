<template>
  <div class="relative" v-click-away="onClickAway">
    <div v-if="profile">
      <button
        @click="showProfileDropdown()"
        class="max-w-xs transition duration-400 ease-out text-black font-semibold
        py-1 uppercase flex items-center text-sm focus:outline-none
           px-1 ml-2"
        id="user-menu"
        aria-haspopup="true"
      >
        <span class="sr-only">Open user menu</span>
        <img
          v-if="profile && profile.logo"
          class="h-16"
          :src="profile.logo.rect"
          alt=""
        />
        <span v-else>Account</span>
      </button>
    </div>
    <div
      class="origin-top-right absolute right-0 mt-2 w-52 rounded-md shadow-lg bg-white ring-1 ring-black z-50
        ring-opacity-5"
      :class="{ hidden: profileDropdown }"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu"
    >
      <div
        class="text-center py-2.5 px-2.5 border-b"
        v-if="profile && profile.user"
      >
        <p class="font-bold text-xl text-gray-800">{{ profile.user.name }}</p>
        <p class="text-gray-500 text-lg">
          {{ profile.user.email }}
        </p>
      </div>
      <a
        href="#"
        class="block px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-red-100 rounded-b-md"
        role="menuitem"
        @click="onSignOut()"
      >
        <div class="flex flex-row items-center justify-center">
          <svg
            version="1.1"
            x="0px"
            y="0px"
            class="w-6"
            viewBox="0 0 490.3 490.3"
            style="enable-background:new 0 0 490.3 490.3;"
            xml:space="preserve"
          >
            <g id="Sign_Out" fill="#b21d1d">
              <path
                d="M0,121.05v248.2c0,34.2,27.9,62.1,62.1,62.1h200.6c34.2,0,62.1-27.9,62.1-62.1v-40.2c0-6.8-5.5-12.3-12.3-12.3
                s-12.3,5.5-12.3,12.3v40.2c0,20.7-16.9,37.6-37.6,37.6H62.1c-20.7,0-37.6-16.9-37.6-37.6v-248.2c0-20.7,16.9-37.6,37.6-37.6h200.6
                c20.7,0,37.6,16.9,37.6,37.6v40.2c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-40.2c0-34.2-27.9-62.1-62.1-62.1H62.1
                C27.9,58.95,0,86.75,0,121.05z"
              />
              <path
                d="M385.4,337.65c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l83.9-83.9c4.8-4.8,4.8-12.5,0-17.3l-83.9-83.9
                c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l63,63H218.6c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3h229.8l-63,63
                C380.6,325.15,380.6,332.95,385.4,337.65z"
              />
            </g>
          </svg>
          <p class="mt-0.5 pl-1.5 text-red-700 text-lg">Sign out</p>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mixin as VueClickAway } from "vue3-click-away";
import {
  // Directives
  VTooltip,
} from "v-tooltip";

export default {
  name: "NavbarAccount",
  mixins: [VueClickAway],
  directives: {
    tooltip: VTooltip,
  },
  props: {
    title: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      profileDropdown: true,
    };
  },
  methods: {
    onClickAway() {
      this.profileDropdown = true;
    },
    showProfileDropdown() {
      this.profileDropdown = false;
    },
    onSignOut() {
      this.$store
        .dispatch("signOut")
        .then(() => {
          this.$router.replace({ name: "Login" });
        })
        .catch(() => {});
    },
  },
  computed: {
    ...mapGetters(["profile"]),
  },
  mounted() {},
};
</script>

<style scoped></style>
